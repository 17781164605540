import { useEffect, useState } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import { Box, Button, FormControl, Grid, Icon, InputLabel, LinearProgress, MenuItem, Paper, Select, Stack, Typography } from '@mui/material';
import { VForm, useVForm } from '../../shared/forms';
import { useDebounce } from '../../shared/hooks';
import { IListagemTipologia, TipologiaService } from '../../shared/services/api/Tipologias/TipologiasService';
import { useNavigate } from 'react-router-dom';

import App from './App.js';

interface IFormData {
	id_tipologia: string;
	arquivo_nome: FileList; // File or FileList
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		paper: {
			position: 'absolute',
			width: 600,
			backgroundColor: theme.palette.background.paper,
			border: '2px solid #000',
			boxShadow: theme.shadows[5],
			padding: theme.spacing(2, 4, 3),
		},
	}),
);

// export default function ModalIncluirAvulso() {
export const ModalIncluirAvulso: React.FC = () => {
	const classes = useStyles();
	const [open, setOpen] = useState(false);
	const { formRef, save } = useVForm();
	const { debounce } = useDebounce(50, false);
	const [isLoadingTipologias, setIsLoadingTipologias] = useState(true);
	const [isUpdatingFiles, setIsUpdatingFiles] = useState(true);
	const [rowsTipologias, setRowsTipologias] = useState<IListagemTipologia[]>([]);
	const [id_tipologia, setId_tipologia] = useState<number | unknown>(null);
	const [carregaFilePond, setCarregaFilePond] = useState(false);
	const [somenteleitura, setSomenteLeitura] = useState(false);
	const [aoMenosUmArquivoCarregado, setAoMenosUmArquivoCarregado] = useState(false);
	const navigate = useNavigate();

	const handleTipologiaChange = (dado: number | unknown) => {
		setCarregaFilePond(false);
		setSomenteLeitura(false);
		if (!dado)
			return;
		setId_tipologia(dado);
		setSomenteLeitura(true);
		setCarregaFilePond(true);
	};

	const handleOpen = () => {
		setOpen(true);
		setIsUpdatingFiles(false);
		setAoMenosUmArquivoCarregado(false);
	};

	const handleClose = () => {
		setSomenteLeitura(false);
		setCarregaFilePond(false);
		setOpen(false);
	};

	useEffect(() => {
		if (isLoadingTipologias) {
			debounce(() => {
				TipologiaService.getAll()
					.then((result) => {
						setIsLoadingTipologias(false);
						if (result instanceof Error) {
							alert(result.message);
							return;
						} else {
							setRowsTipologias(result.data);
						}
					});
			});
		}
	});

	const handleSave = (dados: IFormData) => {
		setSomenteLeitura(false);
		setCarregaFilePond(false);

		navigate(`/listararquivosavulsos?pagina=1&listtipologias=${id_tipologia}`, { replace: true }); //

		setOpen(false);
	};

	return (
		<>
			<Box flex={1}>
				<Button
					color='info'
					disableElevation
					variant='contained'
					onClick={handleOpen}
					startIcon={<Icon>add</Icon>}
				>Novo</Button>
			</Box>
			<Modal
				open={open}
				onClose={handleClose}
			>
				<ModalDialog
					aria-labelledby="nested-modal-title"
					aria-describedby="nested-modal-description"
					className={classes.paper}
					sx={(theme) => ({
						[theme.breakpoints.only('xs')]: {
							top: 'unset',
							bottom: 0,
							left: 0,
							right: 0,
							borderRadius: 0,
							transform: 'none',
							maxWidth: 'unset',
						},
					})}
				>
					<Typography>
						<Box>
							<VForm ref={formRef} onSubmit={handleSave} placeholder={'string'} name='formArquivoAvulso'>
								<Box margin={1} display="flex" flexDirection="column" component={Paper} variant="outlined">
									<Grid container direction="column" padding={2} spacing={2}>

										{isLoadingTipologias && (
											<Grid item >
												<LinearProgress variant='indeterminate' />
											</Grid>
										)}

										{!isLoadingTipologias && (
											<Grid container item direction="row" spacing={2}>
												<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
													<FormControl fullWidth>
														<InputLabel id="labelId_id_tipologia2">Tipologia</InputLabel>
														<Select
															labelId="labelId_id_tipologia2"
															id='id_id_tipologia'
															name='id_tipologia'
															onChange={e => handleTipologiaChange(e.target.value)}
															required
															readOnly={somenteleitura}
															label='Tipologia'>
															{rowsTipologias.map(row => (
																<MenuItem
																	value={row.id_tipologia}
																	key={row.id_tipologia}
																>{row.tipologia_nome}</MenuItem>
															))}
														</Select>
													</FormControl>
												</Grid>
											</Grid>
										)}
										{carregaFilePond && (
											<Grid container item direction="row" spacing={2}>
												<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
													<FormControl fullWidth>
														<App
															id_tipologia={id_tipologia}
															arquivoEnviadoSucesso={(files: any) => {
																setAoMenosUmArquivoCarregado(!!files.length);
																setIsUpdatingFiles(false);
															}}
															arquivoEnviadoErro={() => setIsUpdatingFiles(false)}
															enviandoAquivo={() => setIsUpdatingFiles(true)}
														/>
													</FormControl>
												</Grid>
											</Grid>
										)}
									</Grid>
								</Box>
							</VForm>
						</Box>
					</Typography>

					{!isUpdatingFiles && aoMenosUmArquivoCarregado &&
						<Stack direction={'row'} spacing={15} height='5vh'>
							<Box flex={1} display='flex' justifyContent='end' width={'45%'}>
								<Button
									color='info'
									disableElevation
									variant='contained'
									onClick={handleClose}
									startIcon={<Icon>close</Icon>} >
									<Typography variant='button' whiteSpace='nowrap' textOverflow='ellipsis' overflow='hidden'>
										Fechar
									</Typography>
								</Button>
							</Box>
							<Box flex={1} display='flex' justifyContent='start' width={'45%'}>
								<Button
									color='success'
									disableElevation
									variant='contained'
									onClick={save}
									startIcon={<Icon>check</Icon>} >
									<Typography variant='button' whiteSpace='nowrap' textOverflow='ellipsis' overflow='hidden'>
										OK
									</Typography>
								</Button>
							</Box>
						</Stack>
					}
				</ModalDialog>
			</Modal>
		</>
	);
};
