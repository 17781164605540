import { Pagination, Paper, TableFooter, TableRow, Table, TableBody, TableCell, TableContainer, TableHead, Box, Stack, CircularProgress, Grid, Card, CardContent, Typography } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { FerramentasDaListagem } from '../../shared/components';
import { Enviroment } from '../../shared/enviroments';
import { useDebounce } from '../../shared/hooks';
import { LayoutBaseDePagina } from '../../shared/layouts';
import { IListagemArquivo, Arquivoservice } from '../../shared/services/api/Arquivos/ArquivosService';

const TAM_TEXTO_DADOS_ANTES = 75;
const TAM_TEXTO_DADOS_DEPOIS = 250;
const TAM_TEXTO_TITULO_ANTES = 100;
const TAM_TEXTO_TITULO_DEPOIS = 200;

export const ListagemDeArquivos: React.FC = () => {
	const [searchParams, setSearchParams] = useSearchParams();
	const [totalCount, setTotalCount] = useState(0);
	const navigate = useNavigate();

	const [isLoading, setIsLoading] = useState(true);
	const [rowsFiles, setRowsFiles] = useState<IListagemArquivo[]>([]);
	const { debounce } = useDebounce(50, false);
	const [filtrarVermelho, setFiltrarVermelho] = useState(false);

	const busca = useMemo(() => {
		return searchParams.get('busca') || '';
	}, [searchParams]);

	const pagina = useMemo(() => {
		return Number(searchParams.get('pagina') || '1');
	}, [searchParams]);

	useEffect(() => {
		debounce(() => {
			setIsLoading(true);
			Arquivoservice.getAll(pagina, busca)
				.then((result) => {
					setIsLoading(false);
					if (result instanceof Error) {
						alert(result.message);
						return;
					} else {
						setRowsFiles(result.data);
						busca.length > 0 ? setFiltrarVermelho(true) : null;
					}
				});
			Arquivoservice.getTotal(busca)
				.then((result) => {
					setIsLoading(false);
					if (result instanceof Error) {
						alert(result.message);
						return;
					} else {
						setTotalCount(result.totalCount);
					}
				});
		});
	}, [busca, pagina]);

	return (
		<LayoutBaseDePagina>
			{(
				<Box padding={1} display='flex' justifyContent='center' alignItems='center' sx={{ backgroundColor: '#D3D3D3' }}>
					<TableContainer component={Paper} variant='outlined' sx={{ border: 0 }}>
						<Table sx={{ border: 0 }}>
							<TableHead>
								<TableRow>
									<TableCell colSpan={2} sx={{ border: 0 }}>
										<Box padding={2} display='flex' justifyContent='center' alignItems='center'>
											<FerramentasDaListagem
												mostrarInputBusca={true}
												mostrarBotaoVoltar={false}
												mostrarBotaoNovo={false}
												mostrarBotaoNovoModal={true}
												textoBotaoNovo='Novo'
												textoDaBusca={searchParams.get('busca') ?? ''}
												aoClicarEmNovo={() => { /* handleOpen();*/ }}
												aoMudarTextoDeBusca={texto => { setSearchParams({ busca: texto, pagina: '1' }, { replace: true }); setFiltrarVermelho(false); }}
											/>
										</Box>
									</TableCell>
								</TableRow>
							</TableHead>
							<TableBody sx={{ border: 0 }}>
								{isLoading ? <CircularProgress /> : null}
								{!isLoading && rowsFiles.map(({ arquivo_nome, arquivo_dados }, key) => {
									let posicoesNome: any[] = [];
									let posicoesDados: any[] = [];
									const estaBuscando = !!busca.length;
									if (estaBuscando) {
										posicoesNome = [...arquivo_nome.matchAll(new RegExp(busca, 'gi'))];
										posicoesDados = [...arquivo_dados.matchAll(new RegExp(busca, 'gi'))];
									}
									return (
										<TableRow key={key}>
											<TableCell sx={{ border: 0 }}>
												<Table>
													<TableRow>
														<TableCell>
															{
																!posicoesNome.length &&
																<Typography style={{ fontWeight: 'bold' }}>{arquivo_nome}</Typography>
															}
															{
																!!posicoesNome.length && posicoesNome
																	.map(match => match.index)
																	.map((posicao, key) => {
																		const tamanhoMaximo = arquivo_nome.length;
																		const tamanhoBusca = busca.length;
																		const texto = arquivo_nome.substring(posicao!, posicao! + tamanhoBusca);

																		let posicaoTextoAntecessor = posicao! - texto.length - TAM_TEXTO_TITULO_ANTES;
																		if (posicaoTextoAntecessor < 0)
																			posicaoTextoAntecessor = 0;
																		const posicaoInicialTextoSucessor = posicao! + tamanhoBusca;
																		let posicaoFinalTextoSucessor = posicaoInicialTextoSucessor + TAM_TEXTO_TITULO_DEPOIS;
																		if (posicaoFinalTextoSucessor > tamanhoMaximo)
																			posicaoFinalTextoSucessor = tamanhoMaximo;

																		return (
																			<Box component="span" key={key}>
																				<Typography style={{ fontWeight: 'bold' }}>
																					{`${posicaoTextoAntecessor != 0 ? '...' : ''}${arquivo_nome.substring(posicaoTextoAntecessor, posicao)}`}
																					<Box
																						component="span"
																						sx={{
																							p: 0,
																							color: filtrarVermelho ? 'red' : '',
																							textDecoration: filtrarVermelho ? 'underline' : '',
																							fontWeight: filtrarVermelho ? 'bold' : ''
																						}}>{texto}</Box>
																					{`${arquivo_nome.substring(posicaoInicialTextoSucessor, posicaoFinalTextoSucessor)}${posicaoFinalTextoSucessor < tamanhoMaximo ? '...' : ''}`}
																				</Typography>
																			</Box>
																		);
																	})
															}
														</TableCell>
													</TableRow>
													<TableRow>
														<TableCell>
															{
																!posicoesDados.length && `${arquivo_dados.substring(0, TAM_TEXTO_TITULO_ANTES + TAM_TEXTO_TITULO_DEPOIS)}...`
															}
															{
																!!posicoesDados.length && posicoesDados
																	.map(match => match.index)
																	.map((posicao, key) => {
																		const tamanhoMaximo = arquivo_dados.length;
																		const tamanhoBusca = busca.length;
																		const texto = arquivo_dados.substring(posicao!, posicao! + tamanhoBusca);

																		let posicaoTextoAntecessor = posicao! - texto.length - TAM_TEXTO_DADOS_ANTES;
																		if (posicaoTextoAntecessor < 0)
																			posicaoTextoAntecessor = 0;
																		const posicaoInicialTextoSucessor = posicao! + tamanhoBusca;
																		let posicaoFinalTextoSucessor = posicaoInicialTextoSucessor + TAM_TEXTO_DADOS_DEPOIS;
																		if (posicaoFinalTextoSucessor > tamanhoMaximo)
																			posicaoFinalTextoSucessor = tamanhoMaximo;

																		return (
																			<>
																				<Box component="span" key={key}>
																					{`${posicaoTextoAntecessor != 0 && '...'}${arquivo_dados.substring(posicaoTextoAntecessor, posicao)}`}
																					<Box
																						component="span"
																						sx={{
																							p: 0,
																							color: filtrarVermelho ? 'red' : '',
																							textDecoration: filtrarVermelho ? 'underline' : '',
																							fontWeight: filtrarVermelho ? 'bold' : ''
																						}}>{texto}</Box>
																					{`${arquivo_dados.substring(posicaoInicialTextoSucessor, posicaoFinalTextoSucessor)}${posicaoFinalTextoSucessor < tamanhoMaximo && '...'}`}
																				</Box>
																				<Box component="br" />
																				<Box component="br" />
																			</>
																		);
																	})}
														</TableCell>
													</TableRow>
												</Table>
											</TableCell>
										</TableRow>
									);
								})}
							</TableBody>
							<TableFooter>
								{totalCount === 0 && !isLoading ? (
									<TableRow>
										<TableCell colSpan={2} sx={{ border: 0 }}>
											{Enviroment.LISTAGEM_VAZIA}
										</TableCell>
									</TableRow>
								) : null}
								<TableRow>
									<TableCell colSpan={2} sx={{ border: 0 }}>
										<Box padding={2} display='flex' justifyContent='center' alignItems='center' onClick={() => navigate('/listararquivosprocessos')}>
											<Grid item width={250} height={100} border={1} borderRadius={2} bgcolor='#e7e7e7' style={{ cursor: 'pointer' }}>
												<Box padding={2} display='flex' justifyContent='center' alignItems='center' height={60}>
													<Typography variant='h5' align='center' color='#085784' fontWeight='bold'>
														Meus processos
													</Typography>
												</Box>
											</Grid>
										</Box>
									</TableCell>
								</TableRow>
								{totalCount > 0 && totalCount > Enviroment.LIMITE_DE_LINHAS ? (
									<TableRow>
										<TableCell colSpan={2} sx={{ border: 0 }}>
											<Box padding={2} display='flex' justifyContent='center' alignItems='center'>
												<Pagination
													page={pagina}
													count={Math.ceil(totalCount / Enviroment.LIMITE_DE_LINHAS)}
													onChange={(_, newPage) => setSearchParams({ busca: busca, pagina: newPage.toString() }, { replace: true })}
												/>
											</Box>
										</TableCell>
									</TableRow>
								) : null}
								<TableRow>
									<TableCell colSpan={2} height={200} sx={{ border: 0 }}>
									</TableCell>
								</TableRow>
								<TableRow sx={{ backgroundColor: '#D3D3D3', borderColor: '#D3D3D3' }}>
									<TableCell colSpan={2} sx={{ borderBottom: 0 }}>
										<Box display='flex' justifyContent='right' alignItems='center'
											sx={{ borderTop: 1, borderTopColor: '#999999' }}>
											<Typography align='center' fontWeight='bold'>
												Usuário: nononono
											</Typography>
										</Box>
									</TableCell>
								</TableRow>
							</TableFooter>
						</Table>
					</TableContainer>
				</Box>
			)
			}
		</LayoutBaseDePagina >
	);
};