import { Pagination, Paper, TableFooter, TableRow, Table, TableBody, TableCell, TableContainer, TableHead, IconButton, Icon, Box, Stack, CircularProgress, Typography, Grid, Checkbox, FormControlLabel, Card, CardContent, FormControl, FormGroup, Button, FormLabel, InputLabel } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { FerramentasDaListagem } from '../../shared/components';
import { Enviroment } from '../../shared/enviroments';
import { useDebounce1, useDebounce2, useDebounce3, useDebounce4 } from '../../shared/hooks';
import { LayoutBaseDePagina } from '../../shared/layouts';
import { IDetalheArquivosAvulsosDetalhado, ArquivosAvulsosDetalhadoService } from '../../shared/services/api/ArquivosAvulsosDetalhado/ArquivosAvulsosDetalhadoService';
import { IListagemTipologia, IListagemTipologiaCampos, TipologiaService } from '../../shared/services/api/Tipologias/TipologiasService';
import { NumberFormatBase } from 'react-number-format';

import './mapLinks.css';

export const ListagemDeArquivosAvulsosDetalhado: React.FC = () => {
	const [searchParams, setSearchParams] = useSearchParams();
	const [totalCount, setTotalCount] = useState(0);
	const navigate = useNavigate();

	const [isLoadingTipologias, setIsLoadingTipologias] = useState(true);
	const [isLoadingTipologia, setIsLoadingTipologia] = useState(true);
	const [isLoading, setIsLoading] = useState(true);
	const [rowsTipologia, setRowsTipologia] = useState<IListagemTipologia[]>([]);
	const [rowsTipologiaCampos, setRowsTipologiaCampos] = useState<IListagemTipologiaCampos[]>([]);
	const [rowsFiles, setRowsFiles] = useState<IDetalheArquivosAvulsosDetalhado[]>([]);
	const { debounce1 } = useDebounce1(50, false);
	const { debounce2 } = useDebounce2(50, false);
	const { debounce3 } = useDebounce3(50, false);
	const { debounce4 } = useDebounce4(50, false);
	const [listtipologias, setlisttipologias] = useState('1');
	const { id = '' } = useParams<'id'>();
	const [filtrarVermelho, setFiltrarVermelho] = useState(false);
	const [tipologianome, SetTipologiaNome] = useState('0');

	const busca = useMemo(() => {
		return searchParams.get('busca') || '';
	}, [searchParams]);

	const pagina = useMemo(() => {
		return Number(searchParams.get('pagina') || '1');
	}, [searchParams]);

	useEffect(() => {
		/* ************************************************************************** */
		debounce1(() => {
			if (isLoadingTipologia) {
				TipologiaService.getById(Number(listtipologias))
					.then((result) => {
						setIsLoadingTipologia(false);
						if (result instanceof Error) {
							alert(result.message);
							return;
						} else {
							SetTipologiaNome(result?.tipologia_nome);
						}
					});
			}
		});
		debounce2(() => {
			if (isLoadingTipologias) {
				TipologiaService.getAll()
					.then((result) => {
						setIsLoadingTipologias(false);
						if (result instanceof Error) {
							alert(result.message);
							return;
						} else {
							setRowsTipologia(result.data);
						}
					});
			}
		});
		debounce3(() => {
			setIsLoading(true);
			ArquivosAvulsosDetalhadoService.getAll(pagina, busca, listtipologias, id)
				.then((result) => {
					setIsLoading(false);
					if (result instanceof Error) {
						alert(result.message);
						return;
					} else {
						setRowsFiles(result.data);
						busca.length > 0 ? setFiltrarVermelho(true) : null;
					}
				});
			ArquivosAvulsosDetalhadoService.getTotal(busca, listtipologias, id)
				.then((result) => {
					setIsLoading(false);
					if (result instanceof Error) {
						alert(result.message);
						return;
					} else {
						setTotalCount(result.totalCount);
					}
				});
		});
		debounce4(() => {
			if (isLoadingTipologia) {
				TipologiaService.getFields(listtipologias, id)
					.then((tipologiasCampos) => {
						if (tipologiasCampos instanceof Error) {
							alert(tipologiasCampos.message);
							return;
						} else {
							setRowsTipologiaCampos(tipologiasCampos);
						}
					});
			}
		});
	}, [busca, pagina, listtipologias, id]);

	const geralist = (t: string) => {
		setlisttipologias(t);
		setIsLoadingTipologia(true);
		setSearchParams({ busca: busca, pagina: '1', listtipologias: t }, { replace: true }); // Gera lista string de marcados

	};

	/* ************************************************************************************** */
	let contTipologias = -1;
	let keynum = 0;

	let listcampos = Array(''); // Desmarcar todos
	// Monta o array com os ids dos registros do banco
	listcampos = (rowsTipologiaCampos.map((x) => { return (x.tipologia_campos_fieldname); }));

	return (
		<LayoutBaseDePagina>
			<Stack direction='row'>
				<TableContainer component={Paper} variant='outlined' sx={{ m: 1, width: '25%' }}>
					<Table>
						<TableBody>
							<TableRow>
								<TableCell>
									<Box margin={1} display="flex" flexDirection="column" component={Paper} variant="outlined" sx={{ m: 0, width: '100%' }}>
										<Card>
											<CardContent>
												<FormControl component="fieldset">
													<FormGroup aria-label="position">
														<Box justifyContent='end' height={70} >
															<Button
																color='info'
																disableElevation
																variant='contained'
																onClick={() => { alert('Cadastro de novo processo em breve!'); }}
																startIcon={<Icon>add</Icon>} >
																<Typography variant='button' whiteSpace='nowrap' textOverflow='ellipsis' overflow='hidden'>
																	Processo
																</Typography>
															</Button>
														</Box>
														<Grid item xs={12} sm={12} md={12} lg={12} xl={14} sx={{ padding: '1rem' }}>
															<Typography variant='button' whiteSpace='nowrap'
																textOverflow='ellipsis' overflow='hidden' fontSize={14} >
																Tipologias
															</Typography>
														</Grid>
														{isLoadingTipologias && (
															<CircularProgress />
														)}
														{!isLoadingTipologias && rowsTipologia.map(row => {
															contTipologias++;
															keynum++;
															return (
																<Grid item key={Number(row.id_tipologia)} xs={12} sm={12} md={12} lg={12} xl={14} sx={{ padding: '1rem' }}>
																	<Box sx={{ '&:hover': { cursor: 'pointer' } }}
																		onClick={() => geralist(row.id_tipologia.toString())} >
																		<Typography variant='caption' whiteSpace='nowrap'
																			textOverflow='ellipsis' overflow='hidden' fontSize={14} >
																			{row.tipologia_nome}
																		</Typography>
																	</Box>
																</Grid>
															);
														})}
													</FormGroup>
												</FormControl>
											</CardContent>
										</Card>
									</Box>
								</TableCell>
							</TableRow>
						</TableBody>
					</Table>
				</TableContainer>
				<TableContainer component={Paper} variant='outlined' sx={{ m: 1, width: '75%' }}>
					<Table>
						<TableHead>
							<TableRow>
								<TableCell colSpan={20} align='right'>
									<div className='mapFolder'>
										<div className='mapMain'>
											<div className='mapLink' onClick={() => navigate('/pesquisar')}>Home</div>
											<div className='mapLink' onClick={() => navigate('/listararquivosprocessos')}>&nbsp;/ Processos</div>&nbsp;
										</div>
									</div>
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell colSpan={20} align='left'>
									<Box display="flex" p={1} width={300}>
										{!isLoadingTipologia && tipologianome != '' && (
											<Box p={1} flexGrow={1}>
												<Card variant="outlined" sx={{
													boxShadow: 0,
													borderRadius: 0,
													border: 0,
													p: 0,
													minWidth: 150,
													maxWidth: 200,
												}} >
													<Typography key={0} fontSize={18} align='center' color='#085784' fontWeight=''>
														{tipologianome}
													</Typography>
												</Card>
											</Box>
										)}
									</Box>
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell colSpan={20} align='center'>
									<FerramentasDaListagem
										mostrarInputBuscaIndexado={true}
										mostrarBotaoVoltar={false}
										/* aoClicarEmVoltar={() => navigate('/Dashboard')} */
										mostrarBotaoNovo={true}
										textoBotaoNovo='Novo'
										textoDaBuscaIndexado={searchParams.get('busca') ?? ''}
										tipologiaBuscaIndexado={listtipologias}
										aoClicarEmNovo={() => navigate('/pesquisar')}
										aoMudarTextoDeBuscaIndexado={texto => { setSearchParams({ busca: texto, pagina: '1', busca_id_tipologia: listtipologias }, { replace: true }); }}
									/>
								</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{isLoading && (
								<CircularProgress />
							)}
							<TableRow>
								{!isLoading && rowsTipologiaCampos.map(rowTitulos => {
									keynum++;
									return (
										<TableCell key={keynum}>
											<Typography fontSize={14} color='#085784' fontWeight='bold'>
												{rowTitulos.tipologia_campos_titulo}
											</Typography>
										</TableCell>
									);
								})}
							</TableRow>
							{!isLoading && rowsFiles.map(rowFiles => {
								keynum++;
								return (
									<TableRow key={keynum}>
										{listcampos.map(cmp => {
											let conteudo;
											conteudo = (cmp == 'field01string' ? rowFiles.field01string : conteudo);
											conteudo = (cmp == 'field02string' ? rowFiles.field02string : conteudo);
											conteudo = (cmp == 'field03string' ? rowFiles.field03string : conteudo);
											conteudo = (cmp == 'field04string' ? rowFiles.field04string : conteudo);
											conteudo = (cmp == 'field05string' ? rowFiles.field05string : conteudo);
											conteudo = (cmp == 'field06string' ? rowFiles.field06string : conteudo);
											conteudo = (cmp == 'field07string' ? rowFiles.field07string : conteudo);
											conteudo = (cmp == 'field08string' ? rowFiles.field08string : conteudo);
											conteudo = (cmp == 'field09string' ? rowFiles.field09string : conteudo);
											conteudo = (cmp == 'field10string' ? rowFiles.field10string : conteudo);
											conteudo = (cmp == 'field11string' ? rowFiles.field11string : conteudo);
											conteudo = (cmp == 'field12string' ? rowFiles.field12string : conteudo);
											conteudo = (cmp == 'field13string' ? rowFiles.field13string : conteudo);
											conteudo = (cmp == 'field14string' ? rowFiles.field14string : conteudo);
											conteudo = (cmp == 'field15string' ? rowFiles.field15string : conteudo);
											conteudo = (cmp == 'field16string' ? rowFiles.field16string : conteudo);
											conteudo = (cmp == 'field17string' ? rowFiles.field17string : conteudo);
											conteudo = (cmp == 'field18string' ? rowFiles.field18string : conteudo);
											conteudo = (cmp == 'field19string' ? rowFiles.field19string : conteudo);
											conteudo = (cmp == 'field20string' ? rowFiles.field20string : conteudo);
											conteudo = (cmp == 'field21string' ? rowFiles.field21string : conteudo);
											conteudo = (cmp == 'field22string' ? rowFiles.field22string : conteudo);
											conteudo = (cmp == 'field23string' ? rowFiles.field23string : conteudo);
											conteudo = (cmp == 'field24string' ? rowFiles.field24string : conteudo);
											conteudo = (cmp == 'field25string' ? rowFiles.field25string : conteudo);
											conteudo = (cmp == 'field26string' ? rowFiles.field26string : conteudo);
											conteudo = (cmp == 'field27string' ? rowFiles.field27string : conteudo);
											conteudo = (cmp == 'field28string' ? rowFiles.field28string : conteudo);
											conteudo = (cmp == 'field29string' ? rowFiles.field29string : conteudo);
											conteudo = (cmp == 'field30string' ? rowFiles.field30string : conteudo);
											conteudo = (cmp == 'field31string' ? rowFiles.field31string : conteudo);
											conteudo = (cmp == 'field32string' ? rowFiles.field32string : conteudo);
											conteudo = (cmp == 'field33string' ? rowFiles.field33string : conteudo);
											conteudo = (cmp == 'field34string' ? rowFiles.field34string : conteudo);
											conteudo = (cmp == 'field35string' ? rowFiles.field35string : conteudo);
											conteudo = (cmp == 'field36string' ? rowFiles.field36string : conteudo);
											conteudo = (cmp == 'field37string' ? rowFiles.field37string : conteudo);
											conteudo = (cmp == 'field38string' ? rowFiles.field38string : conteudo);
											conteudo = (cmp == 'field39string' ? rowFiles.field39string : conteudo);
											conteudo = (cmp == 'field40string' ? rowFiles.field40string : conteudo);
											conteudo = (cmp == 'field41string' ? rowFiles.field41string : conteudo);
											conteudo = (cmp == 'field42string' ? rowFiles.field42string : conteudo);
											conteudo = (cmp == 'field43string' ? rowFiles.field43string : conteudo);
											conteudo = (cmp == 'field44string' ? rowFiles.field44string : conteudo);
											conteudo = (cmp == 'field45string' ? rowFiles.field45string : conteudo);
											conteudo = (cmp == 'field46string' ? rowFiles.field46string : conteudo);
											conteudo = (cmp == 'field47string' ? rowFiles.field47string : conteudo);
											conteudo = (cmp == 'field48string' ? rowFiles.field48string : conteudo);
											conteudo = (cmp == 'field49string' ? rowFiles.field49string : conteudo);
											conteudo = (cmp == 'field50string' ? rowFiles.field50string : conteudo);
											conteudo = (cmp == 'field51string' ? rowFiles.field51string : conteudo);
											conteudo = (cmp == 'field52string' ? rowFiles.field52string : conteudo);
											conteudo = (cmp == 'field53string' ? rowFiles.field53string : conteudo);
											conteudo = (cmp == 'field54string' ? rowFiles.field54string : conteudo);
											conteudo = (cmp == 'field55string' ? rowFiles.field55string : conteudo);
											conteudo = (cmp == 'field56string' ? rowFiles.field56string : conteudo);
											conteudo = (cmp == 'field57string' ? rowFiles.field57string : conteudo);
											conteudo = (cmp == 'field58string' ? rowFiles.field58string : conteudo);
											conteudo = (cmp == 'field59string' ? rowFiles.field59string : conteudo);
											conteudo = (cmp == 'field60string' ? rowFiles.field60string : conteudo);
											conteudo = (cmp == 'field61double' ? (new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(Number(rowFiles.field61double))) : conteudo);
											conteudo = (cmp == 'field62double' ? (new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(Number(rowFiles.field62double))) : conteudo);
											conteudo = (cmp == 'field63double' ? (new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(Number(rowFiles.field63double))) : conteudo);
											conteudo = (cmp == 'field64double' ? (new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(Number(rowFiles.field64double))) : conteudo);
											conteudo = (cmp == 'field65double' ? (new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(Number(rowFiles.field65double))) : conteudo);
											conteudo = (cmp == 'field66double' ? (new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(Number(rowFiles.field66double))) : conteudo);
											conteudo = (cmp == 'field67double' ? (new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(Number(rowFiles.field67double))) : conteudo);
											conteudo = (cmp == 'field68double' ? (new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(Number(rowFiles.field68double))) : conteudo);
											conteudo = (cmp == 'field69double' ? (new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(Number(rowFiles.field69double))) : conteudo);
											conteudo = (cmp == 'field70double' ? (new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(Number(rowFiles.field70double))) : conteudo);
											conteudo = (cmp == 'field71date' ? (new Intl.DateTimeFormat('pt-BR', { timeZone: 'UTC' }).format((new Date(rowFiles.field71date)))) : conteudo);
											conteudo = (cmp == 'field72date' ? (new Intl.DateTimeFormat('pt-BR', { timeZone: 'UTC' }).format((new Date(rowFiles.field72date)))) : conteudo);
											conteudo = (cmp == 'field73date' ? (new Intl.DateTimeFormat('pt-BR', { timeZone: 'UTC' }).format((new Date(rowFiles.field73date)))) : conteudo);
											conteudo = (cmp == 'field74date' ? (new Intl.DateTimeFormat('pt-BR', { timeZone: 'UTC' }).format((new Date(rowFiles.field74date)))) : conteudo);
											conteudo = (cmp == 'field75date' ? (new Intl.DateTimeFormat('pt-BR', { timeZone: 'UTC' }).format((new Date(rowFiles.field75date)))) : conteudo);
											conteudo = (cmp == 'field76date' ? (new Intl.DateTimeFormat('pt-BR', { timeZone: 'UTC' }).format((new Date(rowFiles.field76date)))) : conteudo);
											conteudo = (cmp == 'field77date' ? (new Intl.DateTimeFormat('pt-BR', { timeZone: 'UTC' }).format((new Date(rowFiles.field77date)))) : conteudo);
											conteudo = (cmp == 'field78date' ? (new Intl.DateTimeFormat('pt-BR', { timeZone: 'UTC' }).format((new Date(rowFiles.field78date)))) : conteudo);
											conteudo = (cmp == 'field79date' ? (new Intl.DateTimeFormat('pt-BR', { timeZone: 'UTC' }).format((new Date(rowFiles.field79date)))) : conteudo);
											conteudo = (cmp == 'field80date' ? (new Intl.DateTimeFormat('pt-BR', { timeZone: 'UTC' }).format((new Date(rowFiles.field80date)))) : conteudo);
											return (<TableCell key={keynum}>{conteudo}</TableCell>);
										})}
									</TableRow>
								);
							})}
						</TableBody>
						<TableFooter>
							{totalCount === 0 && !isLoading && (
								<TableRow>
									<TableCell colSpan={2}>
										{Enviroment.LISTAGEM_VAZIA}
									</TableCell>
								</TableRow>
							)}
							{totalCount > 0 && totalCount > Enviroment.LIMITE_DE_LINHAS && (
								<TableRow>
									<TableCell colSpan={2}>
										<Box padding={2} display='flex' justifyContent='center' alignItems='center'>
											<Pagination
												page={pagina}
												count={Math.ceil(totalCount / Enviroment.LIMITE_DE_LINHAS)}
												onChange={(_, newPage) => setSearchParams({ busca: busca, pagina: newPage.toString(), busca_id_tipologia: listtipologias }, { replace: true })}
											/>
										</Box>
									</TableCell>
								</TableRow>
							)}
						</TableFooter>
					</Table >
				</TableContainer >
			</Stack >
			<Grid container xs={12} sm={12} md={12} lg={12} xl={14} sx={{ padding: '1rem', justifyContent: 'end' }}>
				<Button
					color='info'
					disableElevation
					variant='contained'
					onClick={() => navigate(-1)}
					startIcon={<Icon>arrow_back</Icon>} >
					<Typography variant='button' whiteSpace='nowrap' textOverflow='ellipsis' overflow='hidden'>
						Voltar
					</Typography>
				</Button>
			</Grid>
		</LayoutBaseDePagina >
	);
};