import React, { useState } from 'react';
import { Pagination, Paper, TableFooter, TableRow, Table, TableBody, TableCell, TableContainer, TableHead, IconButton, Icon, Box, Stack, CircularProgress, Typography, Grid, Checkbox, FormControlLabel, Card, CardContent, FormControl, FormGroup, Button, FormLabel, InputLabel } from '@mui/material';
//import './Login.css'; // Crie o arquivo CSS para estilização

export const Login = () => {
	const [username, setUsername] = useState('');
	const [password, setPassword] = useState('');
	const [showPassword, setShowPassword] = useState(false);

	const handleLogin = (event: any) => {
		event.preventDefault();
		// Aqui você pode adicionar lógica de autenticação
		console.log('Usuário:', username);
		console.log('Senha:', password);
	};

	return (
		<div className="login-container">
			<div className="login-box">
				<h1 className="logo">ARQUIVOTECA</h1>
				<form onSubmit={handleLogin}>
					<div className="input-group">
						<input
							type="text"
							placeholder="Usuário"
							value={username}
							onChange={(e) => setUsername(e.target.value)}
							required
						/>
					</div>
					<div className="input-group">
						<input
							type={showPassword ? 'text' : 'password'}
							placeholder="Senha"
							value={password}
							onChange={(e) => setPassword(e.target.value)}
							required
						/>
						<Typography
							className="toggle-password"
							onClick={() => setShowPassword(!showPassword)}
						>
							<Icon style={{ opacity: .5, fontSize: 18 }}>{showPassword ? 'visibility' : 'visibility_off'}</Icon>
						</Typography>
					</div>
					<button type="submit" className="login-button">Login</button>
					<a href="#" className="forgot-password">Esqueceu a senha?</a>
				</form>
			</div>
		</div>
	);
};