export const Enviroment = {
	LIMITE_DE_LINHAS: 10,
	INPUT_DE_BUSCA: 'Pesquisar...',
	LISTAGEM_VAZIA: 'Nenhum registro encontrado!',
	SECRET_PHRASE: '@rqu1v0t3c@20020520',
	LOCAL_STORAGE_TOKEN: 'APP_ACCESS_TOKEN',

	//URL_BASE: 'http://localhost:51698/v1', // DEV Local

	URL_BASE: 'http://54.88.38.220/v1' // DEV Produção AWS
};